/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/layout';
import { getTranslate } from 'react-localize-redux';
import { hideAllNotifications, showNotification, SEVERITY } from '../state/notifications';
import * as yup from 'yup';
import FormField from '../components/FormField';
import { Form, Field } from 'formik';
import RadioFieldGroup from '../components/RadioFieldGroup';
import RadioField from '../components/RadioField';
import { Container } from '../components';
import Textarea from 'react-textarea-autosize';
import { withFormik } from 'formik';
import RecaptchaButton from '../components/RecaptchaButton';
import * as api from '../utils/api';
import { handleFormValidationError } from '../utils/formError';
import { locNavigate } from '../state/session';
import Spinner from '../components/Spinner';
import * as queryString from 'query-string';
import testLogger from '../components/test-logger';
import { deviceDetect } from 'react-device-detect';
import * as analytics from '../utils/analytics';

const eBusTicketCancellationFormSchema = translate =>
  yup.object().shape({
    name: yup.string().required(translate('eBusTicketCancellationForm.message.requiredField')),
    email: yup
      .string()
      .required(translate('eBusTicketCancellationForm.message.requiredField'))
      .email(translate('eBusTicketCancellationForm.message.invalidEmail')),
    phoneNumber: yup.string(),
    reservationNumber: yup.string(),
    identifier: yup.string().required(translate('eBusTicketCancellationForm.message.requiredField')),
    route: yup.string().required(translate('eBusTicketCancellationForm.message.requiredField')),
    travelDate: yup.string().required(translate('eBusTicketCancellationForm.message.requiredField')),
    departureTime: yup.string().required(translate('eBusTicketCancellationForm.message.requiredField')),
    description: yup.string(),
    cancelAll: yup.string().required(translate('eBusTicketCancellationForm.message.requiredField')),
    recaptcha: yup.string().required(),
  });

const EBusTicketCancellationForm = ({
  translate,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  errors,
  nocaptcha,
  values,
}) => {
  return (
    <Form
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <FormField name="name" label={translate('eBusTicketCancellationForm.name')} />
      <FormField name="email" label={translate('eBusTicketCancellationForm.email')} />
      <FormField name="phoneNumber" label={translate('eBusTicketCancellationForm.phoneNumber')} />
      <Styled.h2>{translate('eBusTicketCancellationForm.subtitle')}</Styled.h2>
      <FormField
        helperText={translate('eBusTicketCancellationForm.reservationNumberInfo')}
        name="reservationNumber"
        label={translate('eBusTicketCancellationForm.reservationNumber')}
      />
      <FormField name="identifier" label={translate('eBusTicketCancellationForm.identifier')} />
      <FormField name="route" label={translate('eBusTicketCancellationForm.route')} />
      <FormField name="travelDate" label={translate('eBusTicketCancellationForm.travelDate')} />
      <FormField name="departureTime" label={translate('eBusTicketCancellationForm.departureTime')} />
      <RadioFieldGroup
        id="cancelAll"
        label={translate('eBusTicketCancellationForm.cancelAllTitle')}
        error={errors.cancelAll}
      >
        <Field
          component={RadioField}
          name="cancelAll"
          id="yes"
          label={translate('eBusTicketCancellationForm.cancelAll')}
        />
        <Field
          component={RadioField}
          name="cancelAll"
          id="no"
          label={translate('eBusTicketCancellationForm.cancelSome')}
        />
      </RadioFieldGroup>
      {values?.cancelAll === 'no' ? (
        <div sx={{ mx: 4 }}>
          <FormField name="description" label={translate('eBusTicketCancellationForm.description')} />
        </div>
      ) : null}

      <RecaptchaButton
        buttonText="eBusTicketCancellationForm.send"
        translate={translate}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        setFieldValue={setFieldValue}
        nocaptcha={nocaptcha}
        errors={errors}
      />
    </Form>
  );
};

const EBusTicketCancellationFormik = withFormik({
  mapPropsToValues: ({ vo }) =>
    Object.assign({
      name: vo.name || '',
      email: vo.email || '',
      phoneNumber: vo.phoneNumber || '',
      reservationNumber: vo.reservationNumber || '',
      identifier: vo.identifier || '',
      route: vo.route || '',
      travelDate: vo.travelDate || '',
      departureTime: vo.departureTime || '',
      description: vo.description || '',
      recaptcha: vo.recaptcha || '',
    }),
  validationSchema: ({ translate }) => {
    return eBusTicketCancellationFormSchema(translate);
  },
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },
  displayName: 'EBusTicketCancellationInputForm',
})(EBusTicketCancellationForm);

const EBusTicketCancellationFormPage = ({ pageContext, location }) => {
  analytics.usePageCategory('lomakkeet');
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);
  const [vo, setVO] = useState({});
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const queryParams = queryString.parse(location.search);
  const { nocaptcha } = queryParams;

  const onSubmit = useCallback(
    async (vo, { setSubmitting }) => {
      setShowSpinner(true);
      dispatch(hideAllNotifications());

      try {
        await api.sendEBusTicketCancellationForm({ ...vo, locale });
        dispatch(
          locNavigate('/thank-you', '', {
            state: {
              message: translate('eBusTicketCancellationForm.message.success'),
              noDefault: true,
            },
          })
        );
        //dispatch(showNotification('eBusTicketCancellationForm.message.success', SEVERITY.INFO));
        setShowSpinner(false);
        setSubmitting(false);
      } catch (error) {
        testLogger.log('sendTicketCancellationForm:FAILED ' + error + ', ' + JSON.stringify(deviceDetect()));
        setShowSpinner(false);
        setSubmitting(false);
        if (handleFormValidationError(error, dispatch, translate)) {
          return;
        }
        console.error(error);
        dispatch(showNotification('eBusTicketCancellationForm.message.failed', SEVERITY.ERROR));
      }
      setVO({});
      window && window.scrollTo(0, 0);
    },
    [dispatch, locale, translate]
  );
  const formProps = { vo, onSubmit, translate, nocaptcha };

  return (
    <Layout
      title={translate('eBusTicketCancellationForm.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
    >
      <Container
        sx={{
          maxWidth: 1024,
          p: [3, 4],
        }}
      >
        <Styled.h1>{translate('eBusTicketCancellationForm.title')}</Styled.h1>
        <p>
          <strong>{translate('eBusTicketCancellationForm.text1')}</strong>
        </p>
        <p>{translate('eBusTicketCancellationForm.text2')}</p>
        <p>{translate('eBusTicketCancellationForm.text3')}</p>
        <p>{translate('eBusTicketCancellationForm.text4')}</p>
        <p>{translate('eBusTicketCancellationForm.text5')}</p>
        <EBusTicketCancellationFormik key={locale} {...formProps} />
        {showSpinner && <Spinner size="medium" position="fixed" />}
      </Container>
    </Layout>
  );
};

export default EBusTicketCancellationFormPage;
